import React from 'react';
import { M10HeroBlock } from '../m10-hero';
import { M75MediaTextBlock } from '../m75-media-text';
import { M80MediaTextBundleBlock } from '../m80-media-text-bundle';
import { M120AccordionBlock } from '../m120-accordion';
import { M90QuoteBlock } from '../m90-quote';
import { M100RichTextBlock } from '../m100-rich-text';
import { M160ArticleFilterBlock } from '../m160-article-filter';
import { SubmenuBlock } from '../submenu';
import { IModules } from '~/lib/data-contract';
import { CookieDeclarationBlock } from '../cookie-declaration';
import { M110FormsModuleBlock } from '../m110-forms-module';
import { M230IPaperBlock } from '../m230-ipaper';
import { M220JobBlock } from '../m220-job-vacancies';
import { M21GridBlock } from '../m21-grid';
import { M170FilterBlock } from '../m170-filter';

export const DynamicBlock = (element: IModules) => {
    switch (element.type) {
        case 'm10HeroModule':
            return <M10HeroBlock {...element} />;

        case 'm21CategoriesModule':
            return <M21GridBlock {...element} />;

        case 'm75MediaAndTextModule':
            return <M75MediaTextBlock {...element} />;

        case 'm80MediaAndTextBundleModule':
            return <M80MediaTextBundleBlock {...element} />;

        case 'm90QuoteModule':
            return <M90QuoteBlock {...element} />;

        case 'm100RichTextModule':
            return <M100RichTextBlock {...element} />;

        case 'm110FormsModule':
            return <M110FormsModuleBlock {...element} />;

        case 'm120AccordionListModule':
            return <M120AccordionBlock {...element} />;

        case 'm160ArticleFilterModule':
            return <M160ArticleFilterBlock {...element} />;

        case 'm170ProductFilterModule':
            return <M170FilterBlock {...element} />;

        case 'm210CookieDeclarationModule':
            return <CookieDeclarationBlock {...element} />;

        case 'm220JobVacanciesModule':
            return <M220JobBlock {...element} />;

        case 'm230IPaperModule':
            return <M230IPaperBlock {...element} />;

        case 'submenuModule':
            return <SubmenuBlock {...element} />;

        default:
            return null;
    }
};
